import axios from "axios";
import {APIs} from "../constants/api.constants";
import {checkCookies, getCookie, removeCookies, setCookies} from "cookies-next";
import Router from "next/router";

export const AuthenticationService = {
  setToken,
  login,
  isLoggedIn,
  getHeaders,
  logout,
  getTokenSavedTime,
  getUserID,
  getRefreshToken,
  refreshTokenApi
}

export function setToken(token, refreshToken, uid) {
  setCookies('admin_token', token, {sameSite: "none", secure: true});
  setCookies('uid', uid, {sameSite: "none", secure: true});
  setCookies('refreshToken', refreshToken, {sameSite: "none", secure: true});
  setCookies('tokenSavedTime', (new Date().getTime()/1000), { sameSite: "none", secure: true });
  return token;
}

export function getHeaders() {
  return 'Bearer ' + getCookie('admin_token', {sameSite: "none", secure: true})
}

export function login(credentials) {
  return axios.post(APIs.authAdmin, credentials);
}

export async function refreshTokenApi(body) {
  const url = APIs.refresh;
  const response = await fetch(url, {
    method: "POST",
    headers: {
        Authorization: 'Bearer '+ AuthenticationService.getRefreshToken(),
        'Content-Type' :'application/json'
    },
    body: JSON.stringify(body),
  });
  response.status === 401 ? Router.push("/login").then(() => {}).then(
      () => {
        logout();
      },
  ) : {};
  return (await response.json());
}

export function logout() {
  removeCookies('admin_token', {sameSite: "none", secure: true});
  removeCookies('refreshToken', {sameSite: "none", secure: true});
  removeCookies('tokenSavedTime', {sameSite: "none", secure: true});
  removeCookies('uid', {sameSite: "none", secure: true});
}

export function getTokenSavedTime() {
  return getCookie('tokenSavedTime', {sameSite: "none", secure: true}) || false;
}

export function getRefreshToken() {
  return getCookie('refreshToken', {sameSite: "none", secure: true}) || false;
}

export function getUserID() {
  return getCookie('uid', {sameSite: "none", secure: true}) || false;
}

export function isLoggedIn() {
  return checkCookies('admin_token', { sameSite: "none", secure: true });
}

export const BASE_URL =
  process?.env?.NEXT_PUBLIC_BACKEND_API || "https://nestjs-dev.cariber.co/api";

export const APIs = {
  authAdmin: BASE_URL + "/admin-auth",
  refresh: BASE_URL + "/auth/refresh",
  accessGroup: BASE_URL + "/accessgroup",
  courses: BASE_URL + "/course",
  episode: BASE_URL + "/episode",
  instructor: BASE_URL + "/instructor",
  users: BASE_URL + "/users",
  subscriptions: BASE_URL + "/subscriptions",
  importCSV: BASE_URL + "/import-csv/import-kajabi-excel",
  importB2BCSV: BASE_URL + "/import-b2b-csv/import-b2b-excel",
  quiz: BASE_URL + "/quiz",
  media: BASE_URL + "/media",
  support: BASE_URL + "/support",
  emailActivityLog: BASE_URL + "/email-activity-log",
  supportUserCreation: BASE_URL + "/support-user-creation-log",
  supportGenCer: BASE_URL + "/support-gen-cer-log",
  tagging: BASE_URL + "/tagging",
  uploadVideo: BASE_URL + "/cloudflare/upload-video",
  uploadSubtitle: BASE_URL + "/cloudflare/upload-subtitle",
  extendSubscription: BASE_URL + "/subscriptions/extend-subscription",
  nextInstructorForm: BASE_URL + "/next-instructor-form",
  getMigratedLog: BASE_URL + "/import-csv/get-migration-data",
  getImportLog: BASE_URL + "/import-b2b-csv/get-import-data",
  assessment: BASE_URL + "/assessment",
  getQuestionsByEpisode: BASE_URL + "/assessment/get-questions-by-episode",
  assessmentResult: BASE_URL + "/assessment-result",
  courseEvaluation: BASE_URL + "/course-evaluation",
};
